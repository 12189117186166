

export function getLabel(key) {

    switch (key) {
        case 'plant':
            return 'Plant';
        case 'plant_location':
            return 'Location';
        case 'plant_price':
            return 'Plant Price';
        case 'label':
            return '';
        case 'bin_location':
            return 'Bin Location';
        case 'unit':
            return 'UOM'
        case 'plant_stock':
            return 'Stock on Hand';
        case 'stock_level':
            return 'Stock on Hand';
        case 'surplus_level':
            return 'Surplus';
        case 'min':
            return 'MIN';
        case 'rop':
            return 'ROP';
        case 'lot_size':
            return 'Lot Size';
        case 'rounding_value':
            return 'Rounding Value';
        case 'max':
            return 'MAX';
        case 'mrp_type':
            return 'MRP Type';
        case 'lead_time':
            return 'Lead Time (days)'
        case 'source':
            return 'Source'
        case 'cl_name':
            return 'Material class'
        case 'attrs':
            return 'Attributes'
        case 'image':
            return 'Image'
        case 'part_number':
            return 'Part Number'
        case 'score':
            return 'Score'
        case 'material':
            return 'Material Number'
        case 'manufacturer':
            return 'Manufacturer'
        case 'commonality':
            return 'Potential Duplicates'
    }
    return key
}
